// Vendors
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Managers
import { HeaderManager } from '../managers';

@Injectable({
  providedIn: 'root'
})
export class HeaderGuard implements CanActivate {

  constructor(
    private _headerManager: HeaderManager
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //@ts-ignore
    let isShowBackBtn = route.data.isShowBackBtn as boolean;
    this._headerManager.toggleBackBtn(isShowBackBtn);
    return true;
  }

}
