import { Injectable } from "@angular/core";
import { ApiContig } from "../config";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class SitemapService {
	constructor(
		private _http: HttpClient
	) { }

	public getSitemap(): Observable<any> {
		const request = ApiContig.sitemap.get;

		//@ts-ignore
		return this._http.get<any>(`${environment.url}${request}`, { responseType: 'text' })
	}
}