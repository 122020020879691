import { Injectable } from "@angular/core";
import { ApiContig } from "../config";
import { HttpClient } from "@angular/common/http";
import { PaginationModel, VisaCardModel } from "../models";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class CardService {
	constructor(
		private _http: HttpClient
	) { }

	public getCards(pageNumber: number, pageSize: number, pageCount: number, search: string): Observable<PaginationModel<VisaCardModel[]>> {
		let request = ApiContig.visaCards.getCards;

		if (search) {
			request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&pageCount=${pageCount}`;
		} else {
			request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}&pageCount=${pageCount}`;
		}

		return this._http.get<PaginationModel<VisaCardModel[]>>(`${environment.url}${request}`);
	}
}