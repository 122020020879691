import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ConverToLatinHelper {
	public convert(str: string): string {
		const isCyrillic = (str) => {
			// Регулярное выражение для поиска кириллических символов
			var cyrillicPattern = /[\u0400-\u04FF]/;
			return cyrillicPattern.test(str);
		}

		const convertToLatin = (str) => {
			var cyrillicToLatinMap = {
				'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
				'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
				'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ъ': '', 'ы': 'y', 'ь': '',
				'э': 'e', 'ю': 'yu', 'я': 'ya'
			};

			// Проверка, является ли строка кириллицей
			if (!isCyrillic(str)) {
				// Если строка не содержит кириллических символов, вернуть как есть
				return str;
			}

			// Преобразование строки
			return str.split('').map(function (char) {
				var lowerChar = char.toLowerCase();
				return cyrillicToLatinMap[lowerChar] || char;
			}).join('');
		}

		if (isCyrillic(str)) {
			var latinString = convertToLatin(str);
			return latinString;
		} else {
			return str;
		}
	}
}