import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class TransformHelper {
	public transformText(text) {
		const englishAlphabet = /[A-Za-z]/;
		const russianAlphabet = /[А-Яа-яЁё]/;
		const azerbaijaniAlphabet = /[ƏəİıÖöÜüÇçŞşĞğ]/;

		const hasEnglish = englishAlphabet.test(text);
		const hasRussian = russianAlphabet.test(text);
		const hasAzerbaijani = azerbaijaniAlphabet.test(text);

		const translitMapRussian = {
			'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'E',
			'Ж': 'ZH', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
			'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
			'Ф': 'F', 'Х': 'KH', 'Ц': 'TS', 'Ч': 'CH', 'Ш': 'SH', 'Щ': 'SHCH', 'Ы': 'Y',
			'Э': 'E', 'Ю': 'YU', 'Я': 'YA', 'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g',
			'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y',
			'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r',
			'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch',
			'ш': 'sh', 'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'yu', 'я': 'ya',
			'ь': '', 'ъ': ''
		};

		const translitMapAzerbaijani = {
			'Ə': 'E', 'ə': 'e', 'İ': 'I', 'ı': 'i', 'Ö': 'O', 'ö': 'o',
			'Ü': 'U', 'ü': 'u', 'Ç': 'C', 'ç': 'c', 'Ş': 'S', 'ş': 's', 'Ğ': 'G', 'ğ': 'g'
		};

		if (hasRussian || hasAzerbaijani) {
			return text.split('').map(char => {
				if (translitMapRussian[char] !== undefined) {
					return translitMapRussian[char];
				} else if (translitMapAzerbaijani[char]) {
					return translitMapAzerbaijani[char];
				} else if (char === ' ') {
					return '-';
				} else {
					return char;
				}
			}).join('');
		} else {
			// Если название на английском языке или состоит из цифр, заменяем пробелы на тире
			return text.replace(/\s+/g, '-');
		}
	}

}