import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TutorialManager {
	public isTutorialSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor() { }

	public toggleTutorial(state: boolean): void {
		this.isTutorialSubj.next(state);
	}
}