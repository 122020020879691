import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfferModel, PaginationModel } from '../models';
import { environment } from 'src/environments/environment';
import { ApiContig, AppConfig } from '../config';
import { ELanguage, ESortTypes } from '../enums';

@Injectable()
export class OffersService {
  constructor(private _http: HttpClient) { }

  public getOffers(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    language: ELanguage,
    includeSpecial: boolean,
    includeGeneral: boolean,
    includeOnlyDiscount: boolean,
    includeOnlyNew: boolean,
    includeOnlyTop: boolean,
    cardIds: string[],
    categoryIds: string[],
    brandId: string,
    isSortedDesc: boolean,
    sordBy: ESortTypes = ESortTypes['date'],
  ): Observable<PaginationModel<OfferModel[]>> {
    let request = ApiContig.offers.getOffers;
    let categoryIdsCopy = [...categoryIds];
    if (categoryIdsCopy.includes(AppConfig.topOfferId)) {
      categoryIdsCopy.splice(categoryIdsCopy.indexOf(AppConfig.topOfferId), 1);
    }

    if (search) {
      request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    request = `${request}&pageCount=${pageCount}&includeSpecial=${includeSpecial}&language=${language}&includeGeneral=${includeGeneral}&includeOnlyTop=${includeOnlyTop}&includeOnlyDiscount=${includeOnlyDiscount}&includeOnlyNew=${includeOnlyNew}${brandId ? `&brandId=${brandId}` : ''
      }&sortedBy=${sordBy}&isSortedDesc=${isSortedDesc}`;

    for (let i = 0; i < cardIds.length; i++) {
      request = `${request}&cardIds=${cardIds[i]}`
    }

    for (let i = 0; i < categoryIdsCopy.length; i++) {
      request = `${request}&categoryIds=${categoryIdsCopy[i]}`
    }

    return this._http.get<PaginationModel<OfferModel[]>>(
      `${environment.url}${request}`,
    );
  }

  public getById(id: string): Observable<OfferModel> {
    const request = `${ApiContig.offers.byId}?id=${id}`;

    return this._http.get<OfferModel>(`${environment.url}${request}`);
  }
}
