// Models
import { OfferModel } from ".";

export class CategoryModel {
	id: string;
	azerbaijaniName: string;
	russianName: string;
	blackImageUrl: string;
	whiteImageUrl: string;
	offers: OfferModel[];
	pageIndex?: number;
}