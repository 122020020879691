import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class OfferManager {
	public selectedOfferIdSubj: BehaviorSubject<string> = new BehaviorSubject('');
	public selectedBrandIdSubj: BehaviorSubject<string> = new BehaviorSubject('');
	public selectedOfferId: string = '';
	public selectedBrandId: string = '';

	public selectOffer(id: string): void {
		this.selectedOfferId = id;
		this.selectedOfferIdSubj.next(id);
	}

	public selectBrand(id: string): void {
		this.selectedBrandId = id;
		this.selectedBrandIdSubj.next(id);
	}
}