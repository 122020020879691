// Vendors
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { EScreenSize } from "../enums";

@Injectable({ providedIn: 'root' })
export class ResizeManager {
    public changeSizeEvent: Subject<null> = new Subject();
    private state: EScreenSize;

    constructor() { }

    public checkSize(): void {
        const width = window.innerWidth;

        if (width <= EScreenSize.md) {
            this.state = EScreenSize['md'];
        } else {
            this.state = EScreenSize['xl'];
        }

        this.changeSizeEvent.next(null);
    }

    public getKey(): string {
        return EScreenSize[this.state];
    }

    public isXl(): boolean {
        return this.state === EScreenSize.xl;
    }

    public isMd(): boolean {
        return this.state === EScreenSize.md;
    }
}