import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SitemapService } from 'src/app/shared/services';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
  providers: [SitemapService]
})
export class SitemapComponent implements OnDestroy, AfterViewInit {
  private _subscription: Subscription = new Subscription();
  public htmlData: any;
  constructor(
    private _sitemapService: SitemapService,
  ) {
    this._getSitemap();

  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  private _getSitemap(): void {
    this._subscription.add(this._sitemapService.getSitemap().subscribe((res) => {
      this.htmlData = res;

    }))
  }
}
