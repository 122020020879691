import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CategoryModel, PaginationModel } from "../models";
import { Observable } from "rxjs";
import { ApiContig } from "../config";
import { environment } from "src/environments/environment";
import { ELanguage } from "../enums";

@Injectable()
export class CategoriesService {
	constructor(
		private _http: HttpClient
	) { }

	public getCategories(pageNumber: number, pageSize: number, pageCount: number, language: ELanguage, IsPrivilege: boolean, search?: string): Observable<PaginationModel<CategoryModel[]>> {
		let request = ApiContig.categories.getCategories;

		if (search) {
			request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
		} else {
			request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
		}

		request = `${request}&pageCount=${pageCount}&language=${language}&IsPrivilege=${IsPrivilege}`

		return this._http.get<PaginationModel<CategoryModel[]>>(`${environment.url}${request}`);
	}
}