import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ESortTypes } from "../enums";
import { Observable } from "rxjs";
import { BrandModel, PaginationModel } from "../models";
import { ApiContig, AppConfig } from "../config";
import { environment } from "src/environments/environment";

@Injectable()
export class BrandService {
	constructor(
		private _http: HttpClient
	) { }

	public getBrands(
		pageNumber: number,
		pageSize: number,
		pageCount: number,
		search: string,
		isSortedDesc: boolean,
		categoryIds: string[],
		includeOnlyWithSpecialOffers: boolean,
		sordBy: ESortTypes = ESortTypes['date']): Observable<PaginationModel<BrandModel[]>> {
		let request = ApiContig.brands.getBrands;
		let categoryIdsCopy = [...categoryIds];
		if (categoryIdsCopy.includes(AppConfig.topOfferId)) {
			categoryIdsCopy.splice(categoryIdsCopy.indexOf(AppConfig.topOfferId), 1);
		}

		if (search) {
			request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
		} else {
			request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
		}

		request = `${request}&pageCount=${pageCount}&sortedBy=${sordBy}&isSortedDesc=${isSortedDesc}`;

		if (includeOnlyWithSpecialOffers) {
			request = `${request}&includeOnlyWithSpecialOffers=${includeOnlyWithSpecialOffers}&includeOnlyWithOffers=false`
		}

		for (let i = 0; i < categoryIds.length; i++) {
			request = `${request}&categoryIds=${categoryIds[i]}`
		}

		return this._http.get<PaginationModel<BrandModel[]>>(`${environment.url}${request}`)
	}
}