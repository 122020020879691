export class ApiContig {
	static offers = {
		getOffers: '/api/Offers/get',
		byId: '/api/Offers/get-by-id'
	};

	static categories = {
		getCategories: '/api/Categories/get',
	}

	static support = {
		sendMail: '/api/Support/send-mail',
	}

	static technical = {
		redirectToVisa: '/api/Technical/get-url-to-visa'
	}

	static catalog = {
		get: '/api/catalog/get'
	}

	static brands = {
		getBrands: '/api/Brands/get',
	}

	static visaCards = {
		getCards: '/api/VisaCards/get',
	}

	static buildings = {
		getBuildings: '/api/Buildings/get',
	}

	static sitemap = {
		get: '/api/Sitemap',
	}
}