import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class HeaderManager {
	public backBtnClickSubj: Subject<null> = new Subject();
	public isShowBackBtnSubj: ReplaySubject<boolean> = new ReplaySubject(1);

	public toggleBackBtn(state: boolean): void {
		this.isShowBackBtnSubj.next(state);
	}

	public backBtnClick(): void {
		this.backBtnClickSubj.next(null);
	}
}