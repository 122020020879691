<router-outlet></router-outlet>

<div class="map-widgets">

	<ng-container *ngFor="let widget of widgets">
		<div class="map-widget" #widgetElement *ngIf="isShowWidgets" [class.yellow]="true" [class.blue]="false"
			[@inOutAnimation] [style.top]="(widget.y - widgetElement.offsetHeight / 2) + 'px'"
			[style.left]="(widget.x - widgetElement.offsetWidth / 2) + 'px'" (click)="openBrandOffers(widget.brandId)"
			[ngClass]="{'discount-diapason': widget.minDiscount > 0 && widget.maxDiscount > 0 && widget.minDiscount < widget.maxDiscount, 'discount-single': (widget.minDiscount > 0 && widget.maxDiscount > 0 && widget.minDiscount === widget.maxDiscount) || widget.hasGifts}">
			<div class="value"
				*ngIf="widget.minDiscount > 0 && widget.maxDiscount > 0 && widget.minDiscount < widget.maxDiscount && !widget.hasGifts">
				<span>{{ widget.minDiscount }}-{{ widget.maxDiscount }}</span> %
			</div>
			<div class="value"
				*ngIf="widget.minDiscount > 0 && widget.maxDiscount > 0 && widget.minDiscount === widget.maxDiscount && !widget.hasGifts">
				<span>{{ widget.minDiscount }}</span>%
			</div>
			<img class="icon" *ngIf="widget.minDiscount === 0 && widget.maxDiscount === 0 && widget.hasGifts"
				src="../assets/img/gift.svg" alt="gift">

		</div>
	</ng-container>
	<!-- <div class="map-widget" [class.yellow]="true"
		style="position: absolute; top: 120rem; left: 300rem; transform: translate(-50%, -50%);z-index: 1000;">
		<img class="icon" src="../assets/img/diamond.svg" alt="icon">
	</div>
	<div class="map-widget" [class.yellow]="true" [class.discount-to]="true" [class.az]="false"
		style="position: absolute; top: 200rem; left: 300rem; transform: translate(-50%, -50%);z-index: 1000;">
		<div class="value">
			<div>до</div>
			<div class="quan">10%</div>
		</div>
	</div> -->

</div>