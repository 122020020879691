// Vendors
import { Injectable } from "@angular/core";

// Enums
import { ECookiesState } from "../enums";

@Injectable({ providedIn: 'root' })
export class CookiesHelper {
	private _localStorageName: string = 'cookieConsent';

	constructor() { }

	public isFirstVisit(): boolean {
		const dataInLocalStorage = JSON.parse(localStorage.getItem(this._localStorageName));

		return dataInLocalStorage ? false : true;
	}

	public acceptCookies(): void {
		localStorage.setItem(this._localStorageName, JSON.stringify(ECookiesState.accepted));
	}

	public rejectCookies(): void {
		localStorage.setItem(this._localStorageName, JSON.stringify(ECookiesState.rejected));
	}




}