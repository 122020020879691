import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ELanguage, ESortTypes } from "../enums";
import { Observable } from "rxjs";
import { CatalogItem, PaginationModel } from "../models";
import { ApiContig, AppConfig } from "../config";
import { environment } from "src/environments/environment";

@Injectable()
export class CatalogService {
	constructor(
		private _http: HttpClient
	) { }

	public getCatalogItems(
		includeOnlyBrandWithOffers: boolean,
		includeOnlyBrandWithSpecialOffers: boolean,
		includeBrand: boolean,
		includePrivilegeOffer: boolean,
		isSortedDesc: boolean,
		sortedBy: ESortTypes,
		partnerId: string,
		categoryIds: string[],
		language: ELanguage,
		pageNumber: number,
		pageSize: number,
		pageCount: number,
		search: string,
	): Observable<PaginationModel<CatalogItem[]>> {
		let request = ApiContig.catalog.get;
		if (categoryIds.includes(AppConfig.topOfferId)) {
			categoryIds.splice(categoryIds.indexOf(AppConfig.topOfferId), 1);
		}

		request = `${request}?includeOnlyBrandWithOffers=${includeOnlyBrandWithOffers}&IncludeOnlyBrandWithSpecialOffers=${includeOnlyBrandWithSpecialOffers}&IncludeBrand=${includeBrand}&IncludePrivilegeOffer=${includePrivilegeOffer}&IsSortedDesc=${isSortedDesc}&SortedBy=${sortedBy}&PartnerId=${partnerId}&Language=${language}&Search=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}&PageCount=${pageCount}`
		for (let i = 0; i < categoryIds.length; i++) {
			request = `${request}&categoryIds=${categoryIds[i]}`
		}
		return this._http.get<PaginationModel<CatalogItem[]>>(`${environment.url}${request}`);
	}
}