import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppResultModel, SendSupportMailModel } from "../models";
import { Observable } from "rxjs";
import { ApiContig } from "../config";
import { environment } from "src/environments/environment";

@Injectable()
export class SupportService {
	constructor(
		private _http: HttpClient
	) { }

	public createFeedback(data: SendSupportMailModel): Observable<AppResultModel> {
		const request = ApiContig.support.sendMail;

		return this._http.post<AppResultModel>(`${environment.url}${request}`, data);
	}
}