import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { filter } from "rxjs";
import { BrandModel } from "../models";
import { ConverToLatinHelper } from "../helpers";

@Injectable({ providedIn: 'root' })
export class AnalyticManager {
	private _previousPageTitle: string = '';
	constructor(private router: Router, private _route: ActivatedRoute, private _gtmService: GoogleTagManagerService, private _converToLatinHelper: ConverToLatinHelper) { }

	trackPageChanges() {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				const previousPageTitle = this._previousPageTitle;
				const currentPageTitle = this.getRouteSnapshot().data['title'];

				const gtmTag = {
					event: 'page_change',
					previousPage: previousPageTitle,
					currentPage: currentPageTitle
				};

				const dataLayer = this._gtmService.getDataLayer();

				this._previousPageTitle = currentPageTitle;
			});
	}

	public initGMT(): void {
		this._gtmService.addGtmToDom();
	}

	public brandClick(brand: BrandModel): void {
		const dataLayer = this._gtmService.getDataLayer();
		const name = brand.name.replace(/ /g, "-");
		const latinName = this._converToLatinHelper.convert(name);
		dataLayer.push({
			event: 'ButtonClick',
			buttonName: latinName
		});
	}

	public initDataLayer(): void {
		const dataLayer = this._gtmService.getDataLayer();
		dataLayer.push({
			MainCategory: 'Visa-paycity',
			SubCategory: 'Catalogue-offers',
			Page: 'NULL',
			SubPage: 'NULL'
		})
	}

	public completeScroll(): void {
		const currentPageTitle = this.getRouteSnapshot().data['title'];
		const gtmTag = {
			event: 'page_scroll',
			pageName: currentPageTitle,
		};

	}

	public buttonClicked(btnName: string): void {
		const currentPageTitle = this.getRouteSnapshot().data['title'];
		const gtmTag = {
			event: 'btn_click',
			btnName: btnName,
		};
	}

	private getRouteSnapshot(): ActivatedRouteSnapshot {
		let route = this._route;

		while (route.firstChild) {
			route = route.firstChild;
		}

		return route.snapshot;
	}
}